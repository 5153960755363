import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaintenanceNotification, MaintenanceTask } from '@maintenance-tasks/maintenance-tasks.models';
import { Observable } from 'rxjs';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class MaintenanceService {
    constructor(private httpClient: HttpClient) {}

    getMaintenanceTasksList(): Observable<MaintenanceTask[]> {
        return this.httpClient.get<any>(`${ PROTECTED_URLS.MAINTENANCE_TASKS }`);
    }

    runMaintenanceTask(data: any): Observable<any> {
        return this.httpClient.post<any>(`${ PROTECTED_URLS.MAINTENANCE_TASKS }run`, data);
    }

    getLastMaintenanceNotification(): Observable<MaintenanceNotification> {
        return this.httpClient.get<any>(`${ PROTECTED_URLS.MAINTENANCE_TASKS }notification`);
    }

    setMaintenanceNotification(data: MaintenanceNotification): Observable<any> {
        return this.httpClient.post<any>(`${ PROTECTED_URLS.MAINTENANCE_TASKS }notification/set`, data);
    }
}
